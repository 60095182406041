import React from "react";
import { graphql, Link } from "gatsby";
// import SharingButtons from '../layouts/ShareBtns'
// import Img from "gatsby-image";
import Slider from "react-slick";
import AOS from 'aos';

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';

export default function Home({data}) {
  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SlideNextArrow />,
      prevArrow: <SlidePrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  const pageContent = data.wordpressPage;
      
  return (
    <PrimaryLayout>
      <SEO 
        title={pageContent.acf.seo_title}
        description={pageContent.acf.seo_description}
        keywords={pageContent.acf.seo_keywords}
      />
      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-5 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-6  mb-2">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <div data-aos="fade-up" dangerouslySetInnerHTML={{__html: pageContent.content}} />
                    <br />
                    <a
                      href="/contact-us"
                      className="button-primary mb-3"
                       data-aos="fade-up"
                    >
                      Contact Us &nbsp;&nbsp;
                      <span>
                        <i className="la la-angle-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="home-top-video" data-aos="fade-up">
                  {/* <SharingButtons className="social-Haligned" title="Ideate. Create. Grow." url="https://storage.googleapis.com/alkye.com/1/2020/08/Alkye-Website-Promo-v03.mp4" /> */}
                  <video
                    className="home-video-top lazyload"
                    controls
                    autoPlay
                    loop
                    muted
                    id="HomeTopVideo"
                    webkit-playsInline={true}
                    playsInline={true}
                    data-src="https://storage.googleapis.com/alkye.com/1/2020/08/Alkye-Website-Promo-v03.mp4"
                  >
                    <source
                      src="https://storage.googleapis.com/alkye.com/1/2020/08/Alkye-Website-Promo-v03.mp4"
                      type="video/mp4"
                    />
                    <track default="" kind="captions" srcLang="en" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid primary-color-bg ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-6">
                <img
                  data-src={pageContent.acf.section_image_01 && pageContent.acf.section_image_01.source_url}
                  className="header-image lazyload"
                  alt=""
                   data-aos="fade-up"
                />
                {/* <Img 
                  fixed={pageContent.acf.section_image_01.localFile.childImageSharp.fixed}
                  alt={pageContent.acf.title_01}
                  /> */}
              </div>
              <div className="col-md-6">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h4 data-aos="fade-up">1.0/</h4>
                    <h2 data-aos="fade-up">
                      {pageContent.acf.title_01}
                    </h2>
                    <p data-aos="fade-up" className="pt-3 text-primary-color" dangerouslySetInnerHTML={{__html: pageContent.acf.paragraph_01}} />
                    <Link
                      // to={pageContent.acf.read_more_01}
                      to="/about-us/"
                      className="button-primary-gray aos-init"
                       data-aos="fade-up"
                    >
                      Read More &nbsp;&nbsp;
                      <span>
                        <i className="la la-angle-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-12">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h4 data-aos="fade-up">2.0/</h4>
                    <h2 data-aos="fade-up">
                      {pageContent.acf.title_02}
                    </h2>
                    <p data-aos="fade-up" className="pt-3" dangerouslySetInnerHTML={{__html: pageContent.acf.paragraph_02}} />

                    <div className="row main-container-padding-t-5">
                      {pageContent.acf.services_list && pageContent.acf.services_list.map((service, index) => (
                        <div className="col-md-4 services-card" key={index} data-aos="fade-up" data-aos-delay={100 * index}>
                          <div id={'services-card'}>
                            <div className="services-card-title">
                              <h5 className="services-card-heading">
                                {service.service_title}
                              </h5>
                              <Link
                                to={service.service_read_more} 
                                // to="/technology/"
                                className="services-link"
                              >
                                <span>
                                  <i className="la la-angle-right"></i>
                                </span>
                              </Link>
                            </div>
                            <div
                              id={'services-card-content'}
                              className="primary-color-bg"
                            >
                              <Link
                                to={service.service_read_more} 
                                // to="/technology/"
                                className="home-services-link"
                              >
                                <h5 className="text-black">{service.service_title}</h5>
                                <p className="text-primary-color smaller-text" dangerouslySetInnerHTML={{__html: service.service_content}} />
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="new-video-section primary-color-bg">
        <div className="container main-container-padding-t-2">
          <div className="row">
            <div className="col-12">
              <div className="header-text-container">
                <div className="vertical-center new-section-width">
                  <h4 data-aos="fade-up">3.0/</h4>
                  <h2 data-aos="fade-up">
                    {pageContent.acf.title_04}
                  </h2>
                  <p data-aos="fade-up" className="pt-3"  dangerouslySetInnerHTML={{__html: pageContent.acf.paragraph_04}} />
                </div>
              </div>
            </div>
          </div>
        </div>
          

        {/* <div className="home-video-slide-inner">
          <video
            className="home-slide-video lazyload"
            controls
            poster="https://storage.googleapis.com/alkye.com/1/2020/08/Simone.jpg"
            id="home-slide-video-1"
            webkit-playsInline={true}
            playsInline={true}
            data-src="https://storage.googleapis.com/alkye.com/1/2020/08/Simone.mp4"
          >
            <source
              src="https://storage.googleapis.com/alkye.com/1/2020/08/Simone.mp4"
              type="video/mp4"
            />
            <track default="" kind="captions" srcLang="en" />
            Your browser does not support the video tag.
          </video>
        </div> */}


        <div className="video-section-container">
          <div className="video-slider-outer" data-aos="fade-up">
            <Slider className="home-video-slider home-slick-nav" {...settings}>
              <div className="home-video-slide-inner">
                <video
                  className="home-slide-video lazyload"
                  controls
                  poster="https://storage.googleapis.com/alkye.com/1/2020/08/Louisa.jpg"
                  id="home-slide-video-2"
                  webkit-playsInline={true}
                  playsInline={true}
                  data-src="https://storage.googleapis.com/alkye.com/1/2020/08/Louisa_Testimonial.mp4"
                >
                  <source
                    src="https://storage.googleapis.com/alkye.com/1/2020/08/Louisa_Testimonial.mp4"
                    type="video/mp4"
                  />
                  <track default="" kind="captions" srcLang="en" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="home-video-slide-inner">
                <video
                  className="home-slide-video lazyload"
                  controls
                  poster="https://storage.googleapis.com/alkye.com/1/2020/08/Gabe.jpg"
                  id="home-slide-video-3"
                  webkit-playsInline={true}
                  playsInline={true}
                  data-src="https://storage.googleapis.com/alkye.com/1/2020/08/Gabriel.mp4"
                >
                  <source
                    src="https://storage.googleapis.com/alkye.com/1/2020/08/Gabriel.mp4"
                    type="video/mp4"
                  />
                  <track default="" kind="captions" srcLang="en" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="home-video-slide-inner">
                <video
                  className="home-slide-video lazyload"
                  controls
                  poster="https://storage.googleapis.com/alkye.com/1/2020/08/Ben.jpg"
                  id="home-slide-video-4"
                  webkit-playsInline={true}
                  playsInline={true}
                  data-src="https://storage.googleapis.com/alkye.com/1/2020/08/Ben.mp4"
                >
                  <source
                    src="https://storage.googleapis.com/alkye.com/1/2020/08/Ben.mp4"
                    type="video/mp4"
                  />
                  <track default="" kind="captions" srcLang="en" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="home-video-slide-inner">
                <video
                  className="home-slide-video lazyload"
                  controls
                  poster="https://alkye.co/wp-content/uploads/2021/06/poster-lisa.png"
                  id="home-slide-video-5"
                  webkit-playsInline={true}
                  playsInline={true}
                  data-src="https://alkye.co/wp-content/uploads/2021/06/testim.mp4"
                >
                  <source
                    src="https://alkye.co/wp-content/uploads/2021/06/testim.mp4"
                    type="video/mp4"
                  />
                  <track default="" kind="captions" srcLang="en" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Slider>
        </div>
      </div>
    </div>

      <div className="container-fluid ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-6">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h4 data-aos="fade-up">4.0/</h4>
                    <h2 data-aos="fade-up">
                      {pageContent.acf.title_03}
                    </h2>
                    <p data-aos="fade-up" className="pt-3" dangerouslySetInnerHTML={{__html: pageContent.acf.paragraph_03}} />
                    <Link
                      // to={pageContent.acf.read_more_03}
                      to="/our-projects/"
                      className="button-primary"
                       data-aos="fade-up"
                    >
                      See our work &nbsp;&nbsp;
                      <span>
                        <i className="la la-angle-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pt-md-0 pt-4">
                <img
                  data-src={pageContent.acf.section_image_03 && pageContent.acf.section_image_03.source_url}
                  className="header-image lazyload"
                  alt="clietns"
                   data-aos="fade-up"
                />
                {/* <Img 
                  fixed={pageContent.acf.section_image_03.localFile.childImageSharp.fixed}
                  alt={pageContent.acf.title_03}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid primary-color-bg ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-4 mb-2">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h4 data-aos="fade-up">5.0/</h4>
                    <h2 data-aos="fade-up">
                      {pageContent.acf.title_05}
                    </h2>
                    <p data-aos="fade-up" className="pt-3 text-primary-color" dangerouslySetInnerHTML={{__html: pageContent.acf.paragraph_05}} />
                    <Link
                      // to={pageContent.acf.read_more_05}
                      to="/get-a-quote/"
                      className="button-primary-gray mb-3"
                       data-aos="fade-up"
                    >
                      GET STARTED&nbsp;&nbsp;
                      <span>
                        <i className="la la-angle-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <img
                  data-src={pageContent.acf.section_image_05 && pageContent.acf.section_image_05.source_url}
                  className="header-image lazyload"
                  alt="get-a-quote-summary"
                   data-aos="fade-up"
                />
                {/* <Img 
                  fixed={pageContent.acf.section_image_05.localFile.childImageSharp.fixed}
                  alt={pageContent.acf.title_05}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-12">
                <div className="header-text-container">
                  <div className="vertical-center">
                    <h4 data-aos="fade-up">6.0/</h4>
                    <h2 data-aos="fade-up">
                      {pageContent.acf.title_06}
                    </h2>
                    <p data-aos="fade-up" className="pt-3" dangerouslySetInnerHTML={{__html: pageContent.acf.paragraph_06}} />
                    <Link
                      // to={pageContent.acf.read_more_06}
                      to="/our-tools/"
                      className="button-primary"
                       data-aos="fade-up"
                    >
                      Our tools &nbsp;&nbsp;
                      <span>
                        <i className="la la-angle-right"></i>
                      </span>
                    </Link>

                    <div className="row main-container-padding-t-5">
                      <div className="col-md-12">
                        <img
                          data-src={pageContent.acf.section_image_06 && pageContent.acf.section_image_06.source_url}
                          className="header-image lazyload"
                          alt="our-too-summary"
                           data-aos="fade-up"
                        />
                        {/* <Img 
                          fixed={pageContent.acf.section_image_06.localFile.childImageSharp.fixed}
                          alt={pageContent.acf.title_06}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  )
}


function SlideNextArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" onClick={onClick} className="home-slick-next slick-arrow">
      <i className="la la-arrow-right"></i>
    </button>
  );
}

function SlidePrevArrow(props) {
  const { onClick } = props;
  return (
    <button type="button" onClick={onClick} className="home-slick-prev slick-arrow">
      <i className="la la-arrow-left"></i>
    </button>
  );
}


export const query = graphql`
{
  wordpressPage(wordpress_id: {eq: 8}) {
    acf {
      title_01
      read_more_01
      paragraph_01
      section_image_01 {
        source_url
      }
      title_02
      paragraph_02
      services_list {
        service_title
        service_read_more
        service_content
      }
      title_03
      read_more_03
      paragraph_03
      section_image_03 {
        source_url
      }
      title_04
      paragraph_04
      title_05
      paragraph_05
      read_more_05
      section_image_05 {
        source_url
      }
      title_06
      paragraph_06
      read_more_06
      section_image_06 {
        source_url
      }
      seo_title
      seo_keywords
      seo_description
    }
    content
  }
}
`